<template>
    <CCard>
        <CCardHeader class="page-header">
            <strong> {{ $t('common.menu.settings.users') }}</strong>
            <back-button backRoute="/settings/global"></back-button>
        </CCardHeader>
        <CCardBody>
            <b-tabs lazy content-class="mt-3">
                <b-tab v-for="(tab,index) in tabs"
                       :key="tab.key"
                       v-if="tab.enabled"
                       :active="activeTab==tab.key"
                >
                    <template slot="title">
                        <font-awesome-icon :icon="tab.icon"></font-awesome-icon>
                        {{ tab.title }}
                        <b-badge v-if="tab.new" href="#" variant="warning">New</b-badge>
                    </template>
                    <component v-bind:is="tab.tab"></component>
                </b-tab>
            </b-tabs>
        </CCardBody>
    </CCard>
</template>

<script>
import {mapGetters} from 'vuex'

const TabUsers = () => import('./users')
const TabRoles = () => import('./roles')

export default {
    name: 'SettingsUsers',
    data() {
        let tabs = [
            {
                'key': 'users',
                'title': this.$t('settings.title.users'),
                'icon': 'user-lock',
                'tab': TabUsers,
                'enabled': true,
            },
            {
                'key': 'roles',
                'title': this.$t('settings.roles.title'),
                'icon': 'user-lock',
                'tab': TabRoles,
                'enabled': true,
            },
        ]

        return {
            'activeTab': 'users',
            'tabs': tabs,
        }
    },
    methods: {
        ...mapGetters('CommonData', ['getMe']),
    },
    mounted() {
        if (this.$route.query.me == '1') {
            this.$root.$children[0].openModal('user-modal', {id: this.getMe().id}, null, {width: '800px'})
        }
    }
}
</script>